<template>
    <RouterView v-slot="{ Component }">
        <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
        >
            <component :is="Component" />
        </transition>
    </RouterView>
</template>

<script setup>
import "bootstrap/dist/css/bootstrap.css"
import "./assets/css/style.css"
import "animate.css"
import "bootstrap/dist/js/bootstrap.bundle.js"
import "bootstrap-icons/font/bootstrap-icons.css"

import { RouterView } from "vue-router"

import { onMounted, onUnmounted } from "vue"
import { useAuthStore } from "./store/auth"

const authStore = useAuthStore()
var cekIsUserLoggedIn = () => {
    authStore.checkAuth()
    let currentUser = authStore.getCurrentUser()
    if (currentUser != null) {
        // authStore.logout()
    }
}

onMounted(() => {
    window.addEventListener("beforeunload", cekIsUserLoggedIn)
})

onUnmounted(() => {
    window.removeEventListener("beforeunload", cekIsUserLoggedIn)
})
</script>

<style>
/* CSS-RESET: https://www.joshwcomeau.com/css/custom-css-reset/ */
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    margin: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #676767;
    border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #474747;
}

html,
body {
    height: 100%;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

/* /CSS-RESET */

@font-face {
    font-family: Komika;
    src: url("./assets/fonts/komika-regular.woff") format("woff");
}

body {
    font-family: Tahoma, Verdana, sans-serif;
    overflow-x: auto;
    _overflow: hidden;
    image-rendering: optimizeQuality;
}

.comic {
    font-family: Komika, Tahoma, Verdana, sans-serif;
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: black;
    text-decoration: none;
}
</style>
