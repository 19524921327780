import { defineStore } from "pinia"
import { buildApiUrl } from "@/utils/url"
import { ILoginResponse, ICurrentUser } from "@/models/user"
import { AxiosError } from "axios"
import Swal from "sweetalert2"

const CURRENT_USER = "user"

function clearCurrentUserLocalStorage() {
    localStorage.removeItem(CURRENT_USER)
}

function loadCurrentUserLocalStorage(): ICurrentUser | null {
    const rawObj: string | null = localStorage.getItem(CURRENT_USER)
    if (rawObj == null) {
        return null
    }
    const parsedObj: ICurrentUser = JSON.parse(rawObj)

    const currentUser: ICurrentUser = {
        user_id: parsedObj.user_id,
        user_name: parsedObj.user_name,
        access: parsedObj.access,
        refresh: parsedObj.refresh,
        token_lifetime: parsedObj.token_lifetime,
        created: parsedObj.created,
        token_expire: parsedObj.token_expire,
    }

    return currentUser
}

function saveCurrentUserLocalStorage(currentUser: ICurrentUser) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(currentUser))
}

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        currentUser: loadCurrentUserLocalStorage(),
    }),
    getters: {
        isAuthenticated: (state) => {
            if (state.currentUser) {
                return true
            }
            return false
        },
    },
    actions: {
        async login(email: string, password: string) {
            const url = buildApiUrl("/auth/jwt/create/")
            await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: ILoginResponse) => {
                        const timestamp_seconds = Math.round(new Date().getTime() / 1000)

                        const currentUser: ICurrentUser = {
                            user_id: data.user_id,
                            user_name: data.user_name,
                            access: data.access,
                            refresh: data.refresh,
                            token_lifetime: data.token_lifetime,
                            created: timestamp_seconds,
                            token_expire: timestamp_seconds + data.token_lifetime,
                        }

                        saveCurrentUserLocalStorage(currentUser)

                        this.currentUser = loadCurrentUserLocalStorage()

                        this.router.push("/start")
                    })
                } else {
                    alert("Login failed email or password is incorrect")
                }
            })
        },
        async checkAuth() {
            if (!this.currentUser) {
                return
            }
            const url = buildApiUrl("/auth/jwt/verify/")
            await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    token: this.currentUser.access,
                }),
            }).then((response) => {
                if (response.status === 200) {
                    // Update token
                } else {
                    clearCurrentUserLocalStorage()
                    this.currentUser = null
                }
            })
        },
        async logout() {
            clearCurrentUserLocalStorage()
            this.currentUser = null
            this.router.push("/")
        },
        getCurrentUser(): ICurrentUser | null {
            return this.currentUser
        },
        async me() {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl("/users/me/")
                    const response = await fetch(url, {
                        headers: { Authorization: `JWT ${token}` },
                    })

                    return await response.json()
                } catch (error) {
                    console.log(error)
                    return false
                }
            }
        },
        async follow(username: string) {
            if (!this.currentUser || !username) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/users/${username}/follow/`)
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { Authorization: `JWT ${token}` },
                    })

                    Swal.fire({
                        title: "Yeah...",
                        text: `Success follow ${username}`,
                        icon: "success",
                        confirmButtonText: "Ok",
                    })

                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },
        async unfollow(username: string) {
            if (!this.currentUser || !username) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/users/${username}/unfollow/`)
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { Authorization: `JWT ${token}` },
                    })

                    Swal.fire({
                        title: "Yeah...",
                        text: `Success unfollow ${username}`,
                        icon: "success",
                        confirmButtonText: "Ok",
                    })

                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },
        async following(username: string) {
            if (!this.currentUser || !username) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/users/${username}/following/`)
                    const response = await fetch(url, {
                        headers: { Authorization: `JWT ${token}` },
                    })

                    return await response.json()
                } catch (error) {
                    console.log(error)
                    return false
                }
            }
        },
        async followers(username: string) {
            if (!this.currentUser || !username) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/users/${username}/followers/`)
                    const response = await fetch(url, {
                        headers: { Authorization: `JWT ${token}` },
                    })

                    return await response.json()
                } catch (error) {
                    console.log(error)
                    return false
                }
            }
        },
        async user(username: string) {
            if (!username) {
                return false
            }

            try {
                const url = buildApiUrl(`/users/${username}/`)
                const response = await fetch(url)
                return await response.json()
            } catch (error) {
                console.log(error)
                return false
            }
        },
        async userInfo(username: string) {
            if (!username) {
                return false
            }

            try {
                const url = buildApiUrl(`/users/${username}/personal_information/`)
                const response = await fetch(url)
                return await response.json()
            } catch (error) {
                console.log(error)
                return false
            }
        },
        async options() {
            try {
                const url = buildApiUrl(`/users/settings/options/`)
                const response = await fetch(url)
                return await response.json()
            } catch (error) {
                console.log(error)
                return false
            }
        },

        async favoritesCategories() {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/categories/favorites/`)
                    const response = await fetch(url, {
                        headers: { Authorization: `JWT ${token}` },
                    })

                    return await response.json()
                } catch (error) {
                    console.log(error)
                    return false
                }
            }
        },

        async favoriteCategory(slug: string) {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/categories/${slug}/favorite/`)
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { Authorization: `JWT ${token}` },
                    })

                    if (response.status == 201)
                        Swal.fire({
                            title: "Yeah...",
                            text: `Success add favorite`,
                            icon: "success",
                            confirmButtonText: "Ok",
                        })
                    else if (response.status == 400)
                        Swal.fire({
                            title: "Oops...",
                            text: `Your are already following this category!`,
                            icon: "warning",
                            confirmButtonText: "Ok",
                        })
                    else
                        Swal.fire({
                            title: "Oops...",
                            text: `error with code ${response.status}`,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },

        async unfavoriteCategory(slug: string) {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/categories/${slug}/unfavorite/`)
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { Authorization: `JWT ${token}` },
                    })

                    if (response.status == 204)
                        Swal.fire({
                            title: "Yeah...",
                            text: `Removed category from your favorites`,
                            icon: "success",
                            confirmButtonText: "Ok",
                        })
                    else if (response.status == 400)
                        Swal.fire({
                            title: "Oops...",
                            text: `Your not following this category!`,
                            icon: "warning",
                            confirmButtonText: "Ok",
                        })
                    else
                        Swal.fire({
                            title: "Oops...",
                            text: `error with code ${response.status}`,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })

                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },

        async savePersonalInformation(data: any) {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/users/personal_information/save/`)
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { Authorization: `JWT ${token}`, "Content-Type": "application/json" },
                        body: JSON.stringify(data),
                    })

                    if (response.status == 200)
                        Swal.fire({
                            title: "Yeah...",
                            text: `Success save personal information`,
                            icon: "success",
                            confirmButtonText: "Ok",
                        })
                    else
                        Swal.fire({
                            title: "Oops...",
                            text: `error with code ${response.status}`,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },

        async getPersonalInformation() {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/users/personal_information/`)
                    const response = await fetch(url, {
                        method: "GET",
                        headers: { Authorization: `JWT ${token}`, "Content-Type": "application/json" },
                    })
                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },

        async getVisibilityFriendship() {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/friends/settings/`)
                    const response = await fetch(url, {
                        method: "GET",
                        headers: { Authorization: `JWT ${token}`, "Content-Type": "application/json" },
                    })
                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },

        async saveVisibilityFriendship(data: any) {
            if (!this.currentUser) {
                return false
            } else {
                try {
                    const token = this.currentUser.access
                    const url = buildApiUrl(`/friends/settings/save/`)
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { Authorization: `JWT ${token}`, "Content-Type": "application/json" },
                        body: JSON.stringify({ friendship_visibility: data }),
                    })

                    if (response.status == 200)
                        Swal.fire({
                            title: "Yeah...",
                            text: `Success save friendship settings to ${data}`,
                            icon: "success",
                            confirmButtonText: "Ok",
                        })
                    else
                        Swal.fire({
                            title: "Oops...",
                            text: `error with code ${response.status}`,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    return await response.json()
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const data = error.response?.data
                        Swal.fire({
                            title: "Oops...",
                            text: data?.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
                    console.log(error)
                    return false
                }
            }
        },
    },
})
